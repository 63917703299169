import { FunctionComponent } from "react";
import { IParallaxTextProps } from "./ParallaxText.d";

import "./ParallaxText.scss";
import React from "react";
import { useTonWallet } from "@tonconnect/ui-react";

export const ParallaxText: FunctionComponent<IParallaxTextProps> = ({
  text,
  isHide = false,
}): JSX.Element => {
  const wallet = useTonWallet();

  const elements: JSX.Element[] = [];

  for (let i = 0; i < 40; i++) {
    elements.push(
      <div key={i} className="text">
        <div className="text_inner1">
          <div className="text_inner2">{text}</div>
        </div>
      </div>
    );
  }
  if (isHide && wallet) {
    return <></>;
  }

  if (wallet) {
    return <div className="ParallaxText_root small">{elements}</div>;
  }

  return <div className="ParallaxText_root">{elements}</div>;
};
