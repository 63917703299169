import {message} from 'antd';

interface FetchPaginationPasswordResponse {
  // Define the structure of the response data here
  // For example:
  data: any[];
}

const fetchPaginationPassword = async (
  token: string,
  currentPage?: number,
): Promise<FetchPaginationPasswordResponse> => {
  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const url = currentPage
    ? `${process.env.REACT_APP_API_URL}/api/slave/pass/my-list?page=${currentPage}&limit=30`
    : `${process.env.REACT_APP_API_URL}/api/slave/pass/my-list?limit=30`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: headers,
    });
    if (!response.ok) {
      throw new Error('Failed to fetch data');
    }
    const data: FetchPaginationPasswordResponse = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export default fetchPaginationPassword;
