import React from "react";
import { motion } from "framer-motion";
import "./ThreeDotsWave.scss";

const spinTransition = {
  repeat: Infinity,
  ease: "easeInOut",
  // width: ['100%', '50%'],
  duration: 1,
};

export const ThreeDotsWave: React.FC<any> = () => {
  return (
    <div className={"ThreeDotsWave_root"}>
      <div className={"ThreeDotsWave_root_styleContainer"}>
        <motion.span
          className={"ThreeDotsWave_root_styleSpan"}
          animate={{ rotate: 360 }}
          transition={spinTransition}
        />
      </div>
    </div>
  );
};
