import React, {useEffect, useState} from 'react';
import './PasswordTableFrame.scss';
import PasswordTable from './PasswordTable/PasswordTable.tsx';
import fetchPaginationPassword from '../../utils/fetchPaginationPassword.ts';
import Pagination from '../../components/Pagination/Pagination.tsx';

const PasswordTableFrame = ({token, passwords, setPasswords}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (passwords && passwords.pagination) {
      setCurrentPage(passwords.pagination.current_page);
      setTotalPages(passwords.pagination.total_pages);
    }
  }, [passwords]);

  useEffect(() => {
    if (token) {
      fetchPaginationPassword(token).then((data) => {
        setPasswords(data);
      });
    }
  }, [token]);

  useEffect(() => {
    fetchPaginationPassword(token, currentPage).then((data) => {
      setPasswords(data);
    });
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="PasswordTableFrame_root">
      <div className="header">
        <div className="header_frame">
          <h3>Ваши проверенные попытки:</h3>
          <span className="checked"></span>
          <b>{passwords.pagination.total - passwords.uncheckeds || 0}</b>
        </div>
        <div className="header_frame">
          <h3>Непроверенные попытки:</h3>
          <span className="unchecked"></span>
          <b>{passwords.uncheckeds || 0}</b>
        </div>
      </div>
      <PasswordTable passwords={passwords ? passwords.items : []} />
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default PasswordTableFrame;
