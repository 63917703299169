import Popup from '../../Popup/Popup.tsx';
import React from 'react';
import './AboutProject.scss';

const AboutProjectNoAuthPopup = ({}) => {
  return (
    <Popup modalID="AboutProjectNoAuth">
      <div className="AboutProject">
        <h2>О проекте</h2>
        <p>
          ТУТ МЫ ПОМОГАЕМ ВСПОМНИТЬ ПАРОЛЬ И ЗАРБАТЫВАЕМ
          <br /> - привяжи свой телеграмм- кошелек для вознаграждений.
          <br /> - вернись на сайт.
          <br /> - вводи свои варианты паролей или воспользуйся нашим
          генератором попыток.
          <br /> - получай токен пр за попытку.
          <br /> - большое вознаграждение за удачную попытку. <br /> - читай
          информацию о кошельке.
        </p>
      </div>
    </Popup>
  );
};
export default AboutProjectNoAuthPopup;
