import {useTonWallet} from '@tonconnect/ui-react';
import PasswordCount from '../../components/PasswordCount/PasswordCount.tsx';
import './Header.scss';
import React, {createRef, useEffect, useState} from 'react';
import cs from 'classnames';
import disconnectUser from '../../utils/authUtils.ts';

import AccountInfo from '../../components/AccountInfo/AccountInfo.tsx';
import {ParallaxText} from '../../components/ParallaxText/ParallaxText.tsx';
import {ThreeDotsWave} from '../../components/ThreeDotsWave/ThreeDotsWave.tsx';
import {useAppDispatch} from '../../store/hooks.ts';
import {openModal} from '../../store/reducers/modalSlice.ts';
interface HeaderProps {
  passwords: any;
}

export const Header: React.FC<HeaderProps> = ({passwords}) => {
  const dispatch = useAppDispatch();

  const [prices, setPrices] = useState({});
  const [openBurgerMenu, setBurgerMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  const wallet = useTonWallet();

  useEffect(() => {
    setIsLoading(true);

    const isMuted = localStorage.getItem('isMuted');
    setIsMuted(isMuted === 'true' ? true : false);

    // Connect to your WebSocket server
    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL || '');
    // Save the previous prices
    let previousPrices = {};

    // Listen to messages from the server
    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.OKX) {
          setIsLoading(false);
          // Update prices state with TON-USDT and BTC-USDT from OKX
          setPrices({
            'TON-USDT': data.OKX['TON-USDT'] || previousPrices['TON-USDT'],
            'BTC-USDT': data.OKX['BTC-USDT'] || previousPrices['BTC-USDT'],
          });
          // Update previous prices
          previousPrices = {
            'TON-USDT': data.OKX['TON-USDT'] || previousPrices['TON-USDT'],
            'BTC-USDT': data.OKX['BTC-USDT'] || previousPrices['BTC-USDT'],
          };
        }
      } catch (error) {
        setIsLoading(false);
        console.error('Ошибка при разборе JSON данных:', error);
      }
    };

    // Clean up the WebSocket connection
    return () => {
      socket.close();
    };
  }, []); // Empty dependency array ensures this effect runs only once;

  const btnRef = createRef<HTMLDivElement>();
  const iconRef = createRef<HTMLElement>();
  const body = document.querySelector<HTMLElement>('body');

  function themeStore(value) {
    localStorage.setItem('darkMode', value);
  }

  const clickButton = () => {
    if (btnRef.current && iconRef.current && body) {
      body.classList.toggle('darkMode');
      iconRef.current.classList.add('animated');

      themeStore(body.classList.contains('darkMode'));
      if (body.classList.contains('darkMode')) {
        iconRef.current.classList.remove('fa-sun');
        iconRef.current.classList.add('fa-moon');
      } else {
        iconRef.current.classList.remove('fa-moon');
        iconRef.current.classList.add('fa-sun');
      }
      setTimeout(() => {
        if (iconRef.current) iconRef.current.classList.remove('animated');
      }, 500);
    }
  };

  const onCopy = () => {
    alert('Адрес скопирован');
    navigator.clipboard.writeText('18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB');
  };

  const pricesContent = (
    <>
      <div className="price">
        TON-USDT Price:
        <span className="price-value">{prices['TON-USDT'] || 0}</span>
      </div>
      <div className="price">
        BTC-USDT Price:
        <span className="price-value">{prices['BTC-USDT'] || 0}</span>
      </div>
    </>
  );

  function mutePage() {
    localStorage.setItem('isMuted', isMuted ? 'false' : 'true');
    setIsMuted(!isMuted);
  }

  useEffect(() => {
    const darkMode = localStorage.getItem('darkMode');
    if (btnRef.current && iconRef.current && body) {
      if (!darkMode) {
        themeStore(false);
        iconRef.current.classList.add('fa-sun');
      } else if (darkMode === 'true') {
        body.classList.add('darkMode');
        iconRef.current.classList.add('fa-moon');
      } else if (darkMode === 'false') {
        if (iconRef.current) iconRef.current.classList.add('fa-sun');
      }
    }
  }, []);

  if (isLoading) return <ThreeDotsWave />;

  return (
    <div className={wallet?.account ? 'Header_root' : 'Header_root_notAuth'}>
      <div className="Header_root__leftWrapper">
        <div className={cs('variables', {full: wallet?.account})}>
          {wallet?.account ? (
            <div className="price-wrapper">{pricesContent}</div>
          ) : (
            pricesContent
          )}

          {wallet?.account && <PasswordCount passwords={passwords} />}
        </div>
      </div>

      {/* {wallet?.account && <img src={logo} className="img" alt="" />} */}
      {wallet?.account && (
        <div className="parallax_auth">
          <ParallaxText text="findpass"></ParallaxText>
        </div>
      )}

      <div className="Header_root__rightWrapper">
        <div className="toggleAndBurger">
          <div className="toggle-theme">
            <div className="btn" ref={btnRef} onClick={() => clickButton()}>
              <div className="btn__indicator">
                <div className="btn__icon-container">
                  <i className="btn__icon fa-solid" ref={iconRef}></i>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`sound-off-button ${isMuted && 'muted'}`}
            onClick={mutePage}></div>
          {wallet?.account && (
            <div
              className={'burgerButton '}
              onClick={() => dispatch(openModal('accountInfoPopup'))}></div>
          )}
        </div>

        {wallet && (
          <AccountInfo
            wallet={wallet}
            onCopy={onCopy}
            disconnectUser={disconnectUser}
            openBurgerMenu={openBurgerMenu}
          />
        )}
      </div>
    </div>
  );
};
