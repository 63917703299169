import React from 'react';
import TwitchEmbedVideo from 'react-twitch-embed-video';

const TwitchPlayer = () => {
  return (
    <>
      <TwitchEmbedVideo
        theme="dark"
        allowfullscreen="false"
        layout="video"
        channel="find_pass"
        width="100%"
        height="100%"
      />
    </>
  );
};

export default TwitchPlayer;
