const disconnectUser = () => {
  console.log("disconnectUser");
  localStorage.removeItem("ton-connect-ui_preferred-wallet");
  localStorage.removeItem("ton-connect-storage_bridge-connection");
  localStorage.removeItem("ton-connect-ui_wallet-info");
  localStorage.removeItem("ton-connect-ui_last-selected-wallet-info");
  localStorage.removeItem(
    "ton-connect-storage_http-bridge-gateway::https://bridge.ton.space/bridge"
  );
  window.location.reload();
};

export default disconnectUser;
