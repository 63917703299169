import Popup from '../../Popup/Popup.tsx';
import React from 'react';
import './AboutProject.scss';

const AboutProjectPopup = ({}) => {
  return (
    <Popup modalID="aboutProject">
      <div className="AboutProject">
        <h2>О проекте</h2>
        <p>
          Мы подбираем забытый пароль кошельков. Можно ввести свой вариант или
          сгенерировать пароль нашей системой - каждая попытка учитывается -
          идет онлайн трансляция - Ваши попытки учтены
        </p>
      </div>
    </Popup>
  );
};
export default AboutProjectPopup;
