import Popup from '../Popup.tsx';
import React, {useEffect, useRef, useState} from 'react';
import './AccountInfoPopup.scss';
import disconnectUser from '../../../utils/authUtils.ts';
import {useTonAddress} from '@tonconnect/ui-react';
import {useAppDispatch} from '../../../store/hooks.ts';
import {closeModal, openModal} from '../../../store/reducers/modalSlice.ts';

interface AccountInfoPopupProps {
  // openAccountInfo: boolean;
  // setOpenAccountInfo: React.Dispatch<React.SetStateAction<boolean>>;
  // onCopy: () => void;
  // setOpenAboutProject: React.Dispatch<React.SetStateAction<boolean>>;
  // setOpenUnlockingApply: React.Dispatch<React.SetStateAction<boolean>>;
  // setBurgerMenu: React.Dispatch<React.SetStateAction<boolean>>;
  // setShowChangeWalletPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const AccountInfoPopup: React.FC<AccountInfoPopupProps> = ({}) => {
  const dispatch = useAppDispatch();

  const userFriendly = useTonAddress();
  const accountInfoRef = useRef<HTMLDivElement>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const handleClickOutside = (event: MouseEvent) => {
  //   if (
  //     accountInfoRef.current &&
  //     !accountInfoRef.current.contains(event.target as Node)
  //   ) {
  //     setOpenAccountInfo(false);
  //   }
  // };

  const onCopy = () => {
    alert('Адрес скопирован');
    navigator.clipboard.writeText('18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB');
  };

  // useEffect(() => {
  //   if (openAccountInfo) {
  //     document.addEventListener('mousedown', handleClickOutside);
  //   } else {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [openAccountInfo]);

  return (
    <Popup modalID="accountInfoPopup">
      <div className="accountInfoPopup" ref={accountInfoRef}>
        <button
          className={'dropdownButton ' + {open: isDropdownOpen}}
          onClick={() => setIsDropdownOpen((prev) => !prev)}>
          {`${userFriendly.slice(0, 4)}...${userFriendly.slice(-4)}`}
        </button>
        {isDropdownOpen && (
          <ul className="actionList">
            <li onClick={onCopy}>
              <div className="imageCopy actionList-image" />
              Копировать адрес
            </li>

            <li onClick={disconnectUser}>
              <div className="imageDisable actionList-image" /> Выйти
            </li>
          </ul>
        )}
        <div className="info">
          {/* <p>
            BTC address: <br /> 18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB
          </p> */}
          <button onClick={() => dispatch(openModal('aboutWallet'))}>
            Об этом кошельке
          </button>

          <button onClick={() => dispatch(openModal('changeWallet'))}>
            Выбрать другой
          </button>

          <button onClick={() => dispatch(openModal('unlockingApply'))}>
            Разблокируйте мой кошелек / заявка
          </button>

          <button onClick={() => dispatch(openModal('aboutProject'))}>
            Если непонятно
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default AccountInfoPopup;
