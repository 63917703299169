import Popup from '../../Popup/Popup.tsx';
import TransactionFrom from '../../sendTon/sendTon.tsx';
import React from 'react';

const BuyAttemptsPopup = () => {
  return (
    <Popup modalID="buyAttempts">
      <TransactionFrom />
    </Popup>
  );
};

export default BuyAttemptsPopup;
