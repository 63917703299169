import React, {useEffect, useState} from 'react';
import './PasswordTable.scss';

const PasswordTable = ({passwords}) => {
  const [prevItem, setPrevItem] = useState('');
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (!passwords.length) return;
    setPrevItem(passwords[0].password);

    const firstItem = document.querySelector(
      '.password-table-container .item',
    ) as HTMLElement;

    if (firstItem && checked && firstItem.textContent?.trim() !== prevItem) {
      firstItem.style.backgroundColor = '#2f80ed40';

      setTimeout(() => {
        firstItem.style.backgroundColor = '';
      }, 3000);
    }
    setChecked(true);
  }, [passwords]);

  return (
    <div className="password-table-container">
      {passwords.map((item) => (
        <div key={item.id} className="item">
          {' '}
          {item.checked ? (
            <span className="yes"></span>
          ) : (
            <span className="not"></span>
          )}
          <p> {item.password}</p>
        </div>
      ))}
    </div>
  );
};

export default PasswordTable;
