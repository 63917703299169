import Popup from '../../Popup/Popup.tsx';
import React from 'react';
import './AboutWallet.scss';

const AboutWalletPopup = ({}) => {
  return (
    <Popup modalID="aboutWallet">
      <div className="AboutWallet">
        <h2> Об этом кошельке</h2>
        <p>
          <span>BTC address: 18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB</span>
          <br />
          Этот кошелек был создан в 2009 году, в то время биткойн не
          воспринимался всерьез. <br /> <br />
          Пароль от кошелька был утерян и теперь у каждого из участников есть
          возможность получить крупное вознаграждение. <br /> <br />
          Ваши донаты за попытки идут на обслуживание серверов и поддержку
          трафика.
        </p>
        <p>
          <span>ГАРАНТИРОВАННОЕ ВОЗНАГРАЖДЕНИЕ</span> <br />
          Лишь один человек, подобравший верный пароль, получит BTC в размере
          300 000 долларов США. <br /> <br />
          Однако, каждый участник проекта также получит гарантированное
          вознаграждение. <br />
          <br />
          10% баланса кошелька будут распределены между всеми пользователями в
          равных долях.
        </p>
      </div>
    </Popup>
  );
};
export default AboutWalletPopup;
