import React, {useEffect, useRef, useState} from 'react';
import './AccountInfo.scss';
import AccountInfoPopup from '../Popup/AccountInfoPopup/AccountInfoPopup.tsx';
import {useTonWallet, useTonAddress} from '@tonconnect/ui-react';
import {useAppDispatch} from '../../store/hooks.ts';
import {openModal} from '../../store/reducers/modalSlice';
import AboutProjectPopup from '../popups/AboutProject/AboutProject.tsx';
import AboutWalletPopup from '../popups/AboutWallet/AboutWallet.tsx';

interface AccountInfoProps {
  wallet: any;
  onCopy: () => void;
  disconnectUser: () => void;
  openBurgerMenu?: boolean;
}

const AccountInfo: React.FC<AccountInfoProps> = ({
  wallet,
  onCopy,
  disconnectUser,
  openBurgerMenu = false,
}) => {
  const dispatch = useAppDispatch();

  const [openAccountInfo, setOpenAccountInfo] = useState(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);

  const userFriendly = useTonAddress();

  const handleButtonClick = (buttonName: string) => {
    setActiveButton(buttonName);
    dispatch(openModal(buttonName));
  };

  const handleMouseEnter = () => {
    setOpenAccountInfo(true);
  };

  const handleMouseLeave = () => {
    setOpenAccountInfo(false);
  };

  const accountInfoContent = (
    <div className="accountInfo" onMouseLeave={handleMouseLeave}>
      <button
        className={'dropdownButton ' + (openAccountInfo ? 'open' : '')}
        // onClick={() => setOpenAccountInfo((prev) => !prev)}
        onMouseEnter={handleMouseEnter}>
        {`${userFriendly.slice(0, 4)}...${userFriendly.slice(-4)}`}
      </button>
      {openAccountInfo && (
        <ul className="actionList" onMouseLeave={handleMouseLeave}>
          <li onClick={onCopy}>
            <div className="imageCopy actionList-image" />
            Копировать адрес
          </li>

          <li onClick={disconnectUser}>
            <div className="imageDisable actionList-image" /> Выйти
          </li>
        </ul>
      )}
      <div className="info">
        {/* <p>
          BTC address: <br /> 18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB
        </p> */}
        <button
          className={activeButton === 'aboutWallet' ? 'active' : ''}
          onClick={() => handleButtonClick('aboutWallet')}>
          Об этом кошельке
        </button>

        <button
          className={activeButton === 'changeWallet' ? 'active' : ''}
          onClick={() => handleButtonClick('changeWallet')}>
          Выбрать другой
        </button>

        <button
          className={activeButton === 'unlockingApply' ? 'active' : ''}
          onClick={() => handleButtonClick('unlockingApply')}>
          Разблокируйте мой кошелек / заявка
        </button>

        <button
          className={activeButton === 'aboutProject' ? 'active' : ''}
          onClick={() => handleButtonClick('aboutProject')}>
          Если непонятно
        </button>
      </div>
    </div>
  );

  return (
    <>
      {wallet.account &&
        (openBurgerMenu ? <AccountInfoPopup /> : accountInfoContent)}
    </>
  );
};

export default AccountInfo;
