import {createSlice, PayloadAction} from '@reduxjs/toolkit';

type ModalState = {
  currentOpenModal: string | null;
};

const initialState: ModalState = {
  currentOpenModal: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.currentOpenModal = null;
    },
    openModal: (state, action: PayloadAction<string>) => {
      state.currentOpenModal = action.payload;
    },
  },
});

export const {closeModal, openModal} = modalSlice.actions;

export default modalSlice.reducer;
