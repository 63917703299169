import React, {useEffect, useState} from 'react';
import {Input, message, Button} from 'antd';

import './PasswordForm.scss';
import {useAppDispatch} from '../../store/hooks';
import {openModal} from '../../store/reducers/modalSlice';
import clickSound from '../../assets/sounds/mario.wav';

message.config({
  maxCount: 2,
});

const PasswordForm = ({onSendPassword}) => {
  const dispatch = useAppDispatch();

  const [animate, setAnimate] = useState(true);
  const [password, setPassword] = useState('');

  // const [isMuted, setIsMuted] = useState(false);
  const audio = new Audio(clickSound);

  let predel = Number(localStorage.getItem('predel')) || 0;

  const handleAnimation = () => {
    setAnimate(true);
    setTimeout(() => setAnimate(false), 700);
  };

  const handleSendPassword = () => {
    const token = localStorage.getItem('token');
    const isMuted = localStorage.getItem('isMuted');

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    if (!password) {
      message.error('Поле пароля не может быть пустым');
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/api/slave/pass/password`, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: JSON.stringify({password}),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.message || 'Ошибка сервера');
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.password) {
          handleAnimation();
          isMuted !== 'true' && audio.play();
          message.success('Пароль отправлен');
          setPassword('');
          predel = predel - 1;
          localStorage.setItem('predel', JSON.stringify(predel));
          onSendPassword();
        } else {
          throw new Error(data.message || 'Ошибка при отправке пароля');
        }
      })
      .catch((error) => {
        message.error(error.message || 'Что-то пошло не так');
      });
  };

  const handleSendPasswordRandom = () => {
    const token = localStorage.getItem('token');
    const isMuted = localStorage.getItem('isMuted');

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };

    fetch(`${process.env.REACT_APP_API_URL}/api/slave/pass/password/random`, {
      method: 'POST',
      headers: {
        ...headers,
      },
      body: '',
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((data) => {
            throw new Error(data.message || 'Ошибка сервера');
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.password) {
          handleAnimation();
          isMuted !== 'true' && audio.play();
          message.success('Пароль отправлен');
          setPassword('');
          const updatePredel = predel - 1;
          localStorage.setItem(
            'predel',
            updatePredel === 0 ? '0' : JSON.stringify(updatePredel),
          );
          onSendPassword();
        } else {
          throw new Error(data.message || 'Ошибка при отправке пароля');
        }
      })
      .catch((error) => {
        message.error(error.message || 'Что-то пошло не так');
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendPassword();
    }
  };

  // useEffect(() => {
  //   const isMuted = localStorage.getItem('isMuted');
  //   setIsMuted(isMuted === 'true' ? true : false);
  // },[]);

  return (
    <div className="PasswordForm_root">
      <div className="input_frame">
        <div className="address">
          <div className="input-container">
            {animate && <div className="coin" />}

            <Input
              className="password-input"
              placeholder="введите пароль"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button className="try-pass" onClick={handleSendPassword}></button>
          </div>
          BTC address: 18jANvQ6AuVGJnea4EhmXiAf6bHR5qKjPB
        </div>
      </div>

      <div className="form-button">
        <div className="try-count">
          Cгенерировать пароль
          <Button className="try-rand" onClick={handleSendPasswordRandom}>
            МНЕ ПОВЕЗЕТ
          </Button>
        </div>
        <div className="try-count">
          Осталось попыток: {predel || 0}
          <Button
            className="try-add"
            onClick={() => dispatch(openModal('buyAttempts'))}>
            ЕЩЕ ПОПЫТОК
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PasswordForm;
