import React from 'react';
import './PasswordCount.scss';

const PasswordCount = ({passwords}) => {
  return (
    <div className="counter">
      <p className="price my">
        Общее количество попыток:
        <span className="price-value">{passwords && passwords.total_all}</span>
      </p>
      <p className="price">
        Колличество ваших FNP токенов:
        <div className="row">
          <span className="price-value">{passwords.pagination.total}</span>
          <span className="coin"></span>
        </div>
      </p>
    </div>
  );
};

export default PasswordCount;
