import React, {useEffect, useState} from 'react';
import {TonConnectUIProvider} from '@tonconnect/ui-react';
import {Header} from './MainPage/Header/Header.tsx';
import {Attempt} from './MainPage/Attempt/Attempt.tsx';
import AboutProjectPopup from './components/popups/AboutProject/AboutProject.tsx';
import WeWinPopup from './components/popups/WeWin/WeWin.tsx';
import UnlockingApplyPopup from './components/popups/UnlockingApply/UnlockingApply.tsx';
import ChangeWalletPopup from './components/popups/ChangeWallet/ChangeWallet.tsx';
import './App.scss';
import {ParallaxText} from './components/ParallaxText/ParallaxText.tsx';
import AboutWalletPopup from './components/popups/AboutWallet/AboutWallet.tsx';
import AccountInfoPopup from './components/Popup/AccountInfoPopup/AccountInfoPopup.tsx';
import BuyAttemptsPopup from './components/popups/BuyAttemptsPopup/BuyAttemptsPopup.tsx';

function App() {
  const [passwords, setPasswords] = useState({
    items: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
    },
    total_all: 0,
  });

  return (
    <TonConnectUIProvider manifestUrl="https://ton.mgsindikat.ru/manifest.json">
      <div className="App">
        <Header passwords={passwords} />

        <ParallaxText text="findpass" isHide />

        <Attempt passwords={passwords} setPasswords={setPasswords} />
      </div>

      <AboutProjectPopup />
      <AboutWalletPopup />
      <AccountInfoPopup />
      <ChangeWalletPopup />
      <UnlockingApplyPopup />
      <BuyAttemptsPopup />
      <WeWinPopup />
    </TonConnectUIProvider>
  );
}

export default App;
