import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ProviderWrapper from './store/provider';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ProviderWrapper>
        <App />
      </ProviderWrapper>
    </React.StrictMode>,
  );
}
